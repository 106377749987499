// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core'

export default function initTypewriter (texts: string[], {
  id
}: {
    id: string
} = {
  id: '#typewriter'
}) {
  const typewriter = new Typewriter(id, {
    loop: true,
    delay: 75
  })

  texts.forEach((text) => {
    typewriter
      .typeString(text)
      .pauseFor(1000)
      .deleteAll(25)
  })

  return typewriter.start()
}
