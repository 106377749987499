<template>
  <div class="container mx-auto px-5">
    <div class="w-full ">
      <div class="flex align-middle justify-center h-[calc(100vh-3.75rem)]">
        <!--suppress VueUnrecognizedDirective -->
        <div
          ref="myPhotoRef"
          class="flex justify-center flex-col align-middle items-center"
        >
          <NuxtImg
            src="/base/profile/me.webp"
            alt="Ros Udom"
            width="250"
            height="250"
          />
          <h1 class="w-fit dark:text-white">
            រស់ ឧត្តម
          </h1>
          <h1>😁🙌😂</h1>
          <p id="typewriter" class="w-fit dark:text-gray-300">
            Frontend Developer
          </p>
          <span ref="scrollDownRef" class="dark:text-white"> បង្វិលចុះក្រោម </span>
        </div>
      </div>
    </div>

    <div class="w-full ">
      <div class="flex align-middle justify-center h-[calc(100vh-3.75rem)]">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!--suppress VueUnrecognizedDirective -->
          <div v-motion-fade-visible class="flex justify-center items-center">
            <Image
              src="/base/image/code-technology.webp"
              alt="Ros Udom"
              class="rounded-full"
              width="350"
              height="350"
            />
          </div>
          <div class="flex items-center">
            <div>
              <h2 class="dark:text-white text-center">
                អំពីខ្ញុំ
              </h2>
              <p class="dark:text-white text-justify">
                សួរស្តី! ខ្ញុំឈ្មោះ <strong>រស់ ឧត្តម</strong>, ជាអ្នកសរសេរកូដម្នាក់ដែលចូលចិត្តផ្តល់យោបល់ និងណែនាំអំពីអ្វីដែលទាក់ទងនឹងការសរសេរកូដ។
                ក្នុងសម័យកាលដ៏ជឿនលឿននេះ, តោះ, ទៅជាមួយខ្ញុំដោយសិក្សាអ្វីគ្រប់យ៉ាងដែលទាក់ទងនិង Web Development, Software Engineering
                និងអ្វីៗច្រើនទៀត...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full ">
      <div class="flex align-middle justify-center h-[calc(100vh-3.75rem)]">
        <div class="flex flex-col w-full justify-center">
          <h2 class="dark:text-white text-center">
            ជំនាញបច្ចេកទេស
          </h2>
          <!--suppress VueUnrecognizedDirective -->
          <div v-motion-fade-visible class="grid grid-cols-[repeat(auto-fill,7rem)] gap-4 mt-5">
            <BaseTag v-for="tag in technicalSkills" :key="tag.title" :link="tag" />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full">
      <div class="flex align-middle justify-center h-[calc(100vh-3.75rem)]">
        <div
          class="flex justify-center flex-col align-middle items-center w-full"
        >
          <h2 class="dark:text-white">
            ទាក់ទងមកខ្ញុំ ហេ! 🤝
          </h2>

          <!--suppress VueUnrecognizedDirective -->
          <div
            v-motion-fade-visible
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 gap-y-5 mt-5 w-full"
          >
            <div
              v-for="detail in myDetail"
              :key="detail.title"
              class="dark:text-white text-center"
            >
              <p class=" dark:text-white text-center">
                {{ detail.title }}:
              </p>
              {{ detail.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Image from 'primevue/image'
import { useMotion } from '@vueuse/motion'
import initTypewriter from '~/utils/typewriter'
import BaseTag from '~/components/BaseTag.vue'

useHead({
  title: 'Homepage - Ros Udom'
})
const scrollDownRef = ref<HTMLSpanElement | null>(null)
const myPhotoRef = ref<HTMLDivElement | null>(null)
const myDetail = [
  {
    title: 'ឆ្នាំកំណើត',
    value: '1994'
  },
  {
    title: 'អាយុ',
    value: `${new Date().getFullYear() - 1994} years`
  },
  {
    title: 'គេហទំព័រ',
    value: 'be-with-you.fun'
  },
  {
    title: 'សញ្ញាប័ត្រ',
    value: 'Master of IT'
  },
  {
    title: 'ទូរស័ព្ទ',
    value: '+855 96 766 065 9'
  },
  {
    title: 'អ៊ីមែល',
    value: 'oudom.ever@gmail.com'
  },
  {
    title: 'ទីកន្លែងបច្ចុប្បន្ន',
    value: 'ភ្នំពេញ'
  },
  {
    title: 'ការងារក្រៅម៉ោង',
    value: 'អាស្រ័យលើគម្រោង អាចពិភាក្សាបាន'
  }
] satisfies Array<{title: string, value: string, type?: 'link' | 'email'}>
const technicalSkills = [
  {
    title: 'Html',
    target: '/'
  },
  {
    title: 'Css',
    target: '/'
  },
  {
    title: 'Javascript',
    target: '/'
  },
  {
    title: 'Vue',
    target: '/'
  },
  {
    title: 'Nuxt',
    target: '/'
  },
  {
    title: 'React',
    target: '/'
  },
  {
    title: 'Node',
    target: '/'
  },
  {
    title: 'Express',
    target: '/'
  },
  {
    title: 'MongoDB',
    target: '/'
  },
  {
    title: 'Mysql',
    target: '/'
  },
  {
    title: 'PostgreSQL',
    target: '/'
  },
  {
    title: 'Docker',
    target: '/'
  },
  {
    title: 'Kubernetes',
    target: '/'
  },
  {
    title: 'Git',
    target: '/'
  },
  {
    title: 'PHP',
    target: '/'
  },
  {
    title: 'Laravel',
    target: '/'
  },
  {
    title: 'Bootstrap',
    target: '/'
  },
  {
    title: 'JQuery',
    target: '/'
  },
  {
    title: 'TailwindCSS',
    target: '/'
  },
  {
    title: 'Nestjs',
    target: '/'
  },
  {
    title: 'Typescript',
    target: '/'
  }
]

const typeWriteContent = ['Frontend Developer', 'Backend Developer', 'Fullstack Developer']

function init () {
  initTypewriter(typeWriteContent)
  const { variant } = useMotion(myPhotoRef, {
    initial: {
      y: 0
    },
    visibleOnce: {
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 350,
        damping: 25,
        onComplete: () => {
          variant.value = 'levitate'
        }
      }
    },
    levitate: {
      y: 15,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: 'easeInOut',
        repeatType: 'reverse'
      }
    }
  })
}

onMounted(() => {
  if (process.client) {
    init()
  }
})
</script>
